<template>
	<el-dialog v-dialogDrag title="选择事件" :visible.sync="visible" width="60%" :before-close="close" @opened="openDialog">
		<div class="flex">
			<div class="w-20 tree-container">
				<div class="all" @click="activeAllFun" :class="{active:activeAll}">全部</div>
				<el-tree check-strictly ref="tree" node-key="id" :default-checked-keys="treeIdArr" show-checkbox
					:highlight-current="!activeAll" :data="tree" @node-click="clickNode"
					:props="{label:'typeName',children: 'children'}"></el-tree>
			</div>
			<div class="w-80">
				<jf-layout>
					<template slot="head">
						<div class="flex_bet">
							<el-form :inline="true">
								<el-form-item>
									<el-input size="small" v-model="pageParam.incidentName" placeholder="请搜索事件名称"
										clearable>
									</el-input>
								</el-form-item>
								<el-form-item>
									<el-button size="small" class="ml10" type="primary" icon="el-icon-search"
										@click="getList('reset')">查询</el-button>
								</el-form-item>
							</el-form>
						</div>
					</template>
					<el-table slot="body" ref="dataTable" border :data="list" v-loading="tableLoading"
						@selection-change="getSelect">
						<el-table-column align="center" type="selection" width="50" :selectable="checkSelectable" />
						<el-table-column align="center" prop="incidentName" label="事件名称" />
						<el-table-column align="center" prop="incidentDesc" label="事件说明" />
						<el-table-column align="center" prop="incidentTypeName" label="事件类型" />
						<el-table-column align="center" label="A分">
							<template slot-scope="{row}">
								{{row.amin||0}}~{{row.amax||0}}
							</template>
						</el-table-column>
						<el-table-column align="center" label="B分">
							<template slot-scope="{row}">
								{{row.bmin||0}}~{{row.bmax||0}}
							</template>
						</el-table-column>
						<el-table-column align="center" label="产值">
							<template slot-scope="{row}">
								{{row.outputMin||0}}~{{row.outputMax||0}}
							</template>
						</el-table-column>
						<el-table-column align="center" prop="incidentStatus" label="状态">
							<template slot-scope="{row}">
								<el-tag type="success" v-if="row.incidentStatus==1">启用</el-tag>
								<el-tag type="warning" v-if="row.incidentStatus==0">禁用</el-tag>
							</template>
						</el-table-column>
						<!-- <el-table-column align="center" label="操作" width="180">
							<template slot-scope="{row}">
								<el-button type="text" size="mini" icon="el-icon-edit" @click="openEvent(row)">编辑
								</el-button>
								<el-button class="c-danger-imp" type="text" size="mini" icon="el-icon-delete"
									@click="delRow(row)">删除</el-button>
							</template>
						</el-table-column> -->
					</el-table>
					<jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit" />
				</jf-layout>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit()" :loading="btnLoading">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				pageParam: {
					page: 1,
					limit: 10
				},
				list: [],
				total: 0,
				tableLoading: false,
				selectArr: [],
				btnLoading: false,
				activeAll: true, //是否点击全部
				tree: [],
				treeIdArr: [],
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			selectTree: {
				type: Array,
				default: () => {
					return []
				}
			},
		},
		computed: {
			visible: {
				get() {
					if (this.show) {
						this.getList()
					}
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		methods: {
			// 打开弹窗时调用
			openDialog() {
				this.treeIdArr = [];
				this.getTreeIdArr(this.selectTree);
				this.getTree();
			},
			//获取tree
			getTree() {
				this.$get('/platform-config/incidenttype/tree').then(res => {
					if (res && res.code == 0) {
						this.tree = res.data;
					}
				})
			},
			// 获取选中的tree 的id数组
			getTreeIdArr(tree) {
				tree.forEach(v => {
					this.treeIdArr.push(v.id);
					if (v.children && v.children.length > 0) {
						this.getTreeIdArr(v.children)
					}
				})
			},
			//获取
			async getList() {
				this.tableLoading = true;
				this.pageParam.templateId = this.$route.query.id;
				let res = await this.$get('/platform-config/incident/selectTemplatePage', this.pageParam);
				this.tableLoading = false;
				if (res && res.code == 0) {
					this.list = res.data.list;
					this.list.forEach((v) => {
						v.incidentTypeName = this.dealIncidentTypeId(v.incidentTypeId, this.tree) || v
							.incidentTypeId;
					})
					this.total = res.data.total;
					this.list.forEach((v) => {
						this.$nextTick(() => {
							this.$refs.dataTable.toggleRowSelection(v, v.isTemp);
						})
						this.selectArr.forEach((k) => {
							if(v.id==k.id){
								this.$nextTick(() => {
									this.$refs.dataTable.toggleRowSelection(v, true);
								})
							}
						})
					})
				}
			},
			//处理回显的事件类型
			dealIncidentTypeId(id, data) {
				let name
				data.map((v) => {
					if (name) return
					if (v.id == id) {
						name = v.typeName
						return
					} else if (v.children && v.children.length > 0) {
						name = this.dealIncidentTypeId(id, v.children)
					}
				})
				return name
			},
			checkSelectable(row, index) {
				return !row.isTemp
			},
			// 关闭
			close() {
				this.$emit('update:show', false)
			},
			// 选择
			getSelect(e) {
				var temArr = []
				e.forEach((k) => {
					let bool = true
					this.selectArr.forEach((v) => {
						if (v.id == k.id) {
							bool = false
						}
					})
					if (bool) {
						temArr.push(k)
					}
				})
				this.selectArr.push(...temArr)
				let arr = JSON.parse(JSON.stringify(this.list));
				e.forEach((v) => {
					let index = arr.indexOf(v);
					arr.splice(index, 1)
				})
				arr.forEach((v) => {
					let index = this.selectArr.indexOf(v);
					if(index!=-1){
						this.selectArr.splice(index, 1)
					}
				})
			},
			// 点击选择全部
			activeAllFun() {
				this.activeAll = true;
				this.pageParam.incidentTypeId = null;
				this.getList()
			},
			// tree 点击
			clickNode(e) {
				this.pageParam.incidentTypeId = e.id;
				this.activeAll = false;
				this.getList()
			},
			async submit() {
				let param = {
					incidentId: [],
					templateId: this.$route.query.id,
					incidentTypeId: this.$refs.tree.getCheckedKeys(),
				}
				if (this.selectArr.length > 0) {
					this.selectArr.map((v) => {
						param.incidentId.push(v.id)
					})
				}
				this.btnLoading = true;
				let res = await this.$post('/platform-config/templateinfo/saveTempIncident', param);
				this.close()
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("选择成功！");
					this.$parent.getList()
					this.$parent.getTree()
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tree-container {
		margin-top: 20px;
		margin-right: 15px;
		border-right: 1px solid #eee;
		overflow: hidden;

		>.all {
			padding: 0 23px;
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #eee;
		}

		.all:hover {
			background-color: #F5F7FA;
		}

		.active {
			background-color: #F5F7FA;
		}
	}

	.tree /deep/ {
		.el-tree-node__content {
			height: 40px !important;
			border-bottom: 1px solid #eee;
		}
	}
</style>
